import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Resource/ResourceTracker/Resource';
import WorkersList from './pages/Resource/ResourceTracker/Workers/Workers';
import DataTable from './pages/Resource/ResourceTracker/DataTable/DataTable';
import Login from './pages/Login/Login';
import { Graph } from './pages/Resource/ResourceTracker/Graph/Graph';
import Maps from './pages/Resource/ResourceTracker/Maps/Maps';
import OnCall from './pages/Resource/ResourceTracker/OnCall/OnCall';
import { useAuth } from './auth/auth';
import { ToastContainer } from 'react-toastify';
import Layout from './Layout/Layout';
import Clients from './pages/Collaboriations/Clients/Clients';
import Projects from './pages/Collaboriations/Projects/Project';
import RTdata from './pages/RT-Data/RTdata';
import PaymentMethod from './pages/Payroll/Payment Methods/PaymentMethod';
import WorkforceMap from './pages/Resource/MapOverview/WorkforceMap';
import Competencies from './pages/Competencies/Competencies';
import Experiences from './pages/Experiences/Experiences';
import Fatigue from './pages/Fatigue/Fatigue';
import RegionsMap from './pages/Resource/MapOverview/RegionsMap/RegionsMap';
import LiveTracker from './pages/Resource/LiveTracker/Livetracker';
import 'react-toastify/dist/ReactToastify.css';
import StaffDetails from './pages/Resource/Staff-Details/StaffDetails';
import LocationLogger from './pages/Resource/ResourceTracker/LocationLogger/LocationLogger';
import GenerateTimesheet from './pages/Reports/Generate Timesheet/GenerateTimesheet';

const App = () => {
  const { state } = useAuth();

  return (
    <>
      {state.user?.token && (
        <Layout>
          <Routes>
            <Route path="/pace-team/resourcing/resource-tracker" element={<Home />} />
            <Route path="/pace-team/resourcing/resource-tracker/workers" element={<WorkersList />} />
            <Route path="/pace-team/resourcing/resource-tracker/table" element={<DataTable />} />
            <Route path="/pace-team/resourcing/resource-tracker/graph" element={<Graph />} />
            <Route path="/pace-team/resourcing/resource-tracker/maps" element={<Maps />} />
            <Route path="/pace-team/resourcing/resource-tracker/on-call" element={<OnCall />} />
            <Route path="/pace-team/resourcing/resource-tracker/generate-timesheet" element={<GenerateTimesheet />} />
            <Route path="/pace-team/resourcing/staff-details" element={<StaffDetails />} />
            <Route path="/pace-team/resourcing/workforce-map" element={<WorkforceMap />} />
            <Route path="/pace-team/resourcing/regions-map" element={<RegionsMap />} />
            <Route path="/pace-team/resourcing/live-tracker" element={<LiveTracker />} />
            <Route path="/pace-team/collaborations/clients" element={<Clients />} />
            <Route path="/pace-team/collaborations/projects" element={<Projects />} />
            <Route path="/pace-team/rt-data" element={<RTdata />} />
            <Route path="/pace-team/payroll/payment-method" element={<PaymentMethod />} />
            <Route path="/pace-team/competencies" element={<Competencies />} />
            <Route path="/pace-team/experiences" element={<Experiences />} />
            <Route path="/pace-team/fatigue" element={<Fatigue />} />
            <Route path="/pace-team/resource-tracker/location-logger" element={<LocationLogger />} />
            <Route path="*" element={<Navigate to="/pace-team/resourcing/resource-tracker" />} />
          </Routes>
        </Layout>
      )}
      {!state.user?.token && (
        <>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </>
      )}
      <ToastContainer />
    </>
  );
};

export default App;
