/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, TextField } from "@mui/material";
import { DataGrid, GridToolbarFilterButton } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import {
    AddNewClientSiteContacts,
    deleteClientSiteContact,
    editClientSiteContact,
    getAllClientSiteContacts
} from "../../../api/apiCall";
import { useAuth } from "../../../auth/auth";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { Modal } from "antd";

const ClientSiteContact = () => {
    const {state} = useAuth();
    const [clientSiteContacts, setClientSiteContacts] = useState<any>([])
    const [, setOpen] = useState(false);

    const handleAddRow = () => {
        const newRow = {
            id: clientSiteContacts.length + 1,
            clientContact: ``
        };
        setClientSiteContacts((prevRows: any) => [newRow, ...prevRows]);
    };

    const handleChange = (id: any, field: any, newValue: any) => {
        const updatedRows = clientSiteContacts.map((row: any) =>
          row.id === id ? { ...row, [field]: newValue } : row
        );
        setClientSiteContacts(updatedRows);
    };

    const columns: any = [
        {
            field: "clientContact",
            headerName: "Client Site Contact",
            flex: 1,
            renderCell: (params: any) => (
                <TextField
                    size="small"
                    value={params.row.clientContact}
                    fullWidth
                    style={{ height: "5px", margin: 5 }}
                    onChange={(event: any) => {
                        handleChange(params.id, "clientContact", event.target.value);
                    }}
                    inputProps={{
                        ...params.inputProps,
                        onKeyDown: (event) => {
                            if (event.key === ' ') {
                                event.stopPropagation();
                            }
                        },
                    }}
                />
              ),
        },
        {
            field: "createdDate",
            headerName: "Created Date",
            flex: 0.3,
            renderCell: (params: any) => (
                <div>
                    {format(new Date(params.row.createdDate || Date.now()), 'dd/MM/yyyy')}
                </div>
            ),
        },
        {
            field: "save",
            headerName: "Save",
            flex: 0.3,
            renderCell: (params: any) => (
                <Button variant="outlined" onClick={()=> handleCellEditCommit(params)}>Save</Button>
            )
        },
        {
            field: "delete",
            headerName: "Delete",
            flex: 0.3,
            renderCell: (params: any) => (
                <Button
                    style={{ backgroundColor: 'red', color: 'white' }}
                    onClick={() => {
                        setOpen(true);
                        Modal.confirm({
                            title: 'Are you sure you want to delete this Client Site Contact?',
                            onOk: () => handleDelete(params.id),
                            okButtonProps: { danger: true },
                            okText: 'YES',
                            cancelText: 'NO',
                            centered: true
                        });
                    }}
                >
                    Delete
                </Button>
            )
        }
    ];

    const handleCellEditCommit = async (params: any) => {
        if(typeof params.row.id === 'number') {
            const response = await AddNewClientSiteContacts(state.user?.token, params.row.clientContact)
            if(response) {
                toast.success(response.data);
                getAllClientSiteContacts(state.user?.token).then(setClientSiteContacts);
            } else {
                toast.error('Something went wrong!')
            }
        } else {
            const response = await editClientSiteContact(state.user?.token, params.row.id,  params.row.clientContact)
            if(response) {
                toast.success(response.data);
            } else {
                toast.error('Something went wrong!')
            }
        }
    };

    const handleDelete = async (id: any) => {
        const response = await deleteClientSiteContact(state.user?.token, id);
        if(response) {
            toast.success(response.data)
            getAllClientSiteContacts(state.user?.token).then(setClientSiteContacts);
        } else {
            toast.error('Something went wrong!')
        }
    }

    const Toolbar = () => {
        return (
            <div className="header-div">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddRow}  
                    style={{
                        boxShadow: 'none'
                    }}
                >
                    Add Client Site Contact
                </Button>
            </div>
        );
    };

    useEffect(() => {
        getAllClientSiteContacts(state.user?.token).then(setClientSiteContacts);
    }, [])

    return (
        <Box 
            sx={{
                width: '100%',
                height: '550px',
                "@media (max-width: 900px)": {
                    overflowX: "auto",
                    margin: 1,
                    minWidth: "95%",
                },
            }}>
            <DataGrid
                rows={clientSiteContacts}
                columns={columns}
                slots={{ toolbar: Toolbar }}
                disableRowSelectionOnClick
                sx={{
                    "@media (max-width: 700px)": {
                        minWidth: "100%",
                    },
                    "& .MuiDataGrid-columnHeader": {
                        color: "#333",
                        fontWeight: "bold",
                        fontSize: 15,
                        backgroundColor: '#f0f0f0',
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: "bold",
                    },
                    "@media (max-width: 600px)": {
                        fontSize: "0.7rem",
                    },
                }}
            />
        </Box>
    );
};

export default ClientSiteContact;
