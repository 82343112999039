/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, TextField } from "@mui/material";
import { DataGrid, GridToolbarFilterButton } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { AddNewWorkerRoleSuggestion, deleteWorkRole, editWorkRoleSuggestion, GetAllWorkRole } from "../../../api/apiCall";
import { useAuth } from "../../../auth/auth";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { Modal } from "antd";

const WorkRoleSuggestions = () => {
    const {state} = useAuth();
    const [workRole, setWorkRole] = useState<any>([])
    const [, setOpen] = useState(false);

    const handleAddRow = () => {
        const newRow = {
            id: workRole.length + 1,
            workRoleName: ``
        };
        setWorkRole((prevRows: any) => [newRow, ...prevRows]);
    };

    const handleChange = (id: any, field: any, newValue: any) => {
        const updatedRows = workRole.map((row: any) =>
          row.id === id ? { ...row, [field]: newValue } : row
        );
        setWorkRole(updatedRows);
    };


    const columns: any = [
        {
            field: "workRoleName",
            headerName: "Work Role Suggestion",
            flex: 1,
            renderCell: (params: any) => (
                <TextField
                    size="small"
                    value={params.row.workRoleName}
                    fullWidth
                    style={{ height: "5px", margin: 5 }}
                    onChange={(event: any) => {
                        handleChange(params.id, "workRoleName", event.target.value);
                    }}
                    inputProps={{
                        ...params.inputProps,
                        onKeyDown: (event) => {
                            if (event.key === ' ') {
                                event.stopPropagation();
                            }
                        },
                    }}
                />
              ),
        },
        {
            field: "createdDate",
            headerName: "Created Date",
            flex: 0.3,
            renderCell: (params: any) => (
                <div>
                    {format(new Date(params.row.createdDate || Date.now()), 'dd/MM/yyyy')}
                </div>
            ),
        },
        {
            field: "save",
            headerName: "Save",
            flex: 0.3,
            renderCell: (params: any) => (
                <Button variant="outlined" onClick={()=> handleCellEditCommit(params)}>Save</Button>
            )
        },
        {
            field: "delete",
            headerName: "Delete",
            flex: 0.3,
            renderCell: (params: any) => (
                <Button
                    style={{ backgroundColor: 'red', color: 'white' }}
                    onClick={() => {
                        setOpen(true);
                        Modal.confirm({
                            title: 'Are you sure you want to delete this Work Role Suggestion?',
                            onOk: () => handleDelete(params.id),
                            okButtonProps: { danger: true },
                            okText: 'YES',
                            cancelText: 'NO',
                            centered: true
                        });
                    }}
                >
                    Delete
                </Button>
            )
        }
    ];

    const handleCellEditCommit = async (params: any) => {
        if(typeof params.row.id === 'number') {
            const response = await AddNewWorkerRoleSuggestion(state.user?.token, params.row.workRoleName)
            if(response) {
                toast.success(response.data);
                GetAllWorkRole(state.user?.token).then(setWorkRole);
            } else {
                toast.error('Something went wrong!')
            }
        } else {
            const response = await editWorkRoleSuggestion(state.user?.token, params.row.id, params.row.workRoleName)
            if(response) {
                toast.success(response.data);
            } else {
                toast.error('Something went wrong!')
            }
        }
    };

    const handleDelete = async (id: any) => {
        const response = await deleteWorkRole(state.user?.token, id);
        if(response) {
            toast.success(response.data);
            GetAllWorkRole(state.user?.token).then(setWorkRole);
        } else {
            toast.error('Something went wrong!')
        }
    }

    const Toolbar = () => {
        return (
            <div className="header-div">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddRow}  
                    style={{
                        boxShadow: 'none'
                    }}
                >
                    Add Work Role
                </Button>
            </div>
        );
    };

    useEffect(() => {
        GetAllWorkRole(state.user?.token).then(setWorkRole);
    }, [])

    return (
        <Box
            sx={{
                width: '100%',
                height: '550px',
                "@media (max-width: 900px)": {
                    overflowX: "auto",
                    margin: 1,
                    minWidth: "95%",
                },
            }}>
            <DataGrid
                rows={workRole}
                columns={columns}
                slots={{ toolbar: Toolbar }}
                disableRowSelectionOnClick
                sx={{
                    "@media (max-width: 700px)": {
                        minWidth: "100%",
                    },
                    "& .MuiDataGrid-columnHeader": {
                        color: "#333",
                        fontWeight: "bold",
                        fontSize: 15,
                        backgroundColor: '#f0f0f0',
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: "bold",
                    },
                    "@media (max-width: 600px)": {
                        fontSize: "0.7rem",
                    },
                }}
            />
        </Box>
    );
};

export default WorkRoleSuggestions;
