import React, { useEffect, useState } from "react";
import { Autocomplete, Button, CircularProgress, ListItem, TextField } from "@mui/material";
import { addDays, format } from "date-fns";
import { DateRangePicker } from 'react-date-range';
import { generateFatigueReport, GetWorkersSuggestions } from "../../api/apiCall";
import { useAuth } from "../../auth/auth";
import './Fatigue.css'

const Fatigue = () => {
  const {state} = useAuth();
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState<any>([]);
  const [id, setId] = useState();
  const [name, setName] = useState();
  const typingDelay = 100;
  let typingTimer: any;
  const [dates, setState] = useState<any>([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);

  const getWeeklyReport = async () => {
    setLoading(true);
    const response = await generateFatigueReport(
      state.user?.token,
      format(dates[0].startDate, 'yyyy-MM-dd'),
      format(dates[0].endDate, 'yyyy-MM-dd'),
      id,
      name
    );
    if(response) {
      setLoading(false);
    }else { 
      setLoading(false);
    }
  }

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        const response = await GetWorkersSuggestions(
          state.user?.token,
          inputValue
        );

        if (response) {
          setSuggestions(response.data);
        } else {
          console.error("Error fetching suggestions");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (inputValue) {
      typingTimer = setTimeout(fetchSuggestions, typingDelay);
    }

    return () => clearTimeout(typingTimer);
  }, [inputValue]);

  const handleNameChange = async (newName: any) => {
    setId(newName.userId);
    setName(newName.fullName)
  }

  return (
    <>
      <h2 className="h2-element">Download Fatigue Report</h2>
      <div className="box-div">
        <div className="fullname-label">
          <div className="fullname-label-text">Full Name:</div>
          <Autocomplete
            size="small"
            options={suggestions || []}
            disableClearable
            style={{ margin: 5, width: '400px' }}
            getOptionLabel={(option: any) => `${option?.fullName || ""}`}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            onChange={(event, newValue) => {
              handleNameChange(newValue);
            }}
            renderOption={(props, option) => (
              <ListItem {...props} key={option.fullName}>
                {option.fullName} - {option.pts}
              </ListItem>
            )}
            renderInput={(params) =>
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  onKeyDown: (event) => {
                    if (event.key === ' ') {
                      event.stopPropagation();
                    }
                  },
                }}
              />
            }
          />
        </div>
        <div className="div-range-date">
          <DateRangePicker
            onChange={item => setState([item.selection])}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={dates}
            direction="horizontal"
          />
        </div>
        <div className="export-button">
          <Button
            variant="contained"
            disabled={loading}
            style={{
              margin: "20px",
              color: "white",
              height: "40px",
              width: "250px",
              boxShadow: "none",
            }}
            onClick={getWeeklyReport}>
            {loading?
              <CircularProgress size={25} style={{color: 'white'}} /> : 'Export Fatigues'
            }
          </Button>    
        </div>
      </div>
    </>
  )
}

export default Fatigue;
