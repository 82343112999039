/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
    addNewExperience,
    editExperience,
    getAllExperiences,
} from "../../api/apiCall";
import { useAuth } from "../../auth/auth";
import { format } from "date-fns";
import { toast } from "react-toastify";

const Experiences = () => {
    const {state} = useAuth();
    const [experiences, setExperiences] = useState<any>([]);

    const handleAddRow = () => {
        const newRow = {
            id: experiences.length + 1,
            name: ``
        };
        setExperiences((prevRows: any) => [newRow, ...prevRows]);
    };

    const handleChange = (id: any, field: any, newValue: any) => {
        const updatedRows = experiences.map((row: any) =>
          row.id === id ? { ...row, [field]: newValue } : row
        );
        setExperiences(updatedRows);
    };

    const columns: any = [
        {
            field: "experienceName",
            headerName: "Experience Type",
            flex: 1,
            renderCell: (params: any) => (
                <TextField
                    size="small"
                    value={params.row.experienceName}
                    fullWidth
                    style={{ height: "5px", margin: 5 }}
                    onChange={(event: any) => {
                        handleChange(params.id, "experienceName", event.target.value);
                    }}
                    inputProps={{
                        ...params.inputProps,
                        onKeyDown: (event) => {
                            if (event.key === ' ') {
                                event.stopPropagation();
                            }
                        },
                    }}
                />
              ),
        },
        {
            field: "createdDate",
            headerName: "Added Date",
            flex: 0.3,
            renderCell: (params: any) => (
                <div>
                    {format(new Date(params.row.createdDate || Date.now()), 'dd/MM/yyyy')}
                </div>
            ),
        },
        {
            field: "save",
            headerName: "Save",
            flex: 0.3,
            renderCell: (params: any) => (
                <Button variant="outlined" onClick={()=> handleCellEditCommit(params)}>Save</Button>
            )
        }
    ];

    const handleCellEditCommit = async (params: any) => {
        if(typeof params.row.id === 'number') {
            const response = await addNewExperience(state.user?.token, params.row.experienceName)
            if(response) {
                toast.success(response.data);
                getAllExperiences(state.user?.token).then(setExperiences);
            } else {
                toast.error('Something went wrong!')
            }
        } else {
            const response = await editExperience(state.user?.token, params.row.id, params.row.experienceName)
            if(response) {
                toast.success(response.data);
            } else {
                toast.error('Something went wrong!')
            }
        }
    };

    useEffect(() => {
        getAllExperiences(state.user?.token).then(setExperiences);
    }, [])

    return (
        <div>
            <div className="add-button">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddRow}
                    style={{
                        boxShadow: 'none'
                    }}
                >
                    Add Experience
                </Button>
            </div>
            <Box 
                sx={{
                    width: "60%",
                    height: '840px',
                    margin: "0 auto",
                    mt: 4,
                    "@media (max-width: 800px)": {
                        overflowX: "auto",
                        margin: 1,
                        minWidth: "95%",
                    },
                }}
            >
                <DataGrid
                    rows={experiences}
                    columns={columns}
                    disableRowSelectionOnClick
                    sx={{
                        "@media (max-width: 700px)": {
                            minWidth: "100%",
                        },
                        "& .MuiDataGrid-columnHeader": {
                            color: "#333",
                            fontWeight: "bold",
                            fontSize: 15,
                            backgroundColor: '#f0f0f0',
                        },
                        "& .MuiDataGrid-columnHeaderTitle": {
                            fontWeight: "bold",
                        },
                        "& .MuiDataGrid-row:hover": {
                            backgroundColor: "#f1f1f1",
                        },
                    }}
                />
            </Box>
        </div>
    );
};

export default Experiences;
