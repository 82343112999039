import React, { useEffect, useState } from "react";
import { Button, CircularProgress, TextField } from "@mui/material";
import { useAuth } from "../../../auth/auth";
import Header from "../../Resource/components/Header/Header";
import { useSearchParams } from "react-router-dom";
import './GenerateTimesheet.css'
import { generateColasTimesheet } from "../../../api/apiCall";

const GenerateTimesheet = () => {
  const {state} = useAuth();
  const [date, setDate] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [week, setWeek] = useState<any>(searchParams.get('week') || 1);
  const [year, setYear] = useState<any>(searchParams.get('year') || 2024);
  const [loading, setLoading] = useState(false);
  const [labourOrderNumber, setLabourOrderNumber] = useState()

  const generateTimesheet = async () => {
    try {
      setLoading(true)
      const response = await generateColasTimesheet(state.user?.token, week, year, labourOrderNumber);
      if(response){
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    setSearchParams({ week, year });
  }, [date]);

  return (
    <>
      <Header
        setDate={setDate}
        week={week}
        setWeek={setWeek}
        year={year}
        setYear={setYear}
      />
      <div className="form-container">
        <div className="timesheet-form">
          <h2 className="h2-element">Colas Midweek Timesheet</h2>
          <TextField
            variant="outlined"
            label="Labour Order Number"
            placeholder="Enter Labour Order Number"
            value={labourOrderNumber}
            onChange={(e: any) => setLabourOrderNumber(e.target.value)}
            InputProps={{
              style: { backgroundColor: "#f9f9f9", borderRadius: "8px" },
            }}
          />
          <Button
            variant="contained"
            disabled={loading}
            size="large"
            className="export-button"
            onClick={generateTimesheet}
          >
            {loading ? (
              <CircularProgress size={25} style={{ color: "white" }} />
            ) : (
              "Export Timesheet"
            )}
          </Button>
        </div>
      </div>
    </>
  )
}

export default GenerateTimesheet;
