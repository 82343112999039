/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Autocomplete, ListItem } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
    addWorkerToGeofence,
    getUsersFromGeofence,
    GetWorkersSuggestions,
    removeWorkerFromGeofence
} from "../../../../../api/apiCall";
import { useAuth } from "../../../../../auth/auth";
import { toast } from "react-toastify";
import { Modal } from "antd";

const Workers = ({geofence}: any) => {
    const {state} = useAuth();
    const [workers, setWorkers] = useState<any>([]);
    const [inputValue, setInputValue] = useState("");
    const [suggestions, setSuggestions] = useState<any>([]);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [workerIdToDelete, setWorkerIdToDelete] = useState<any>();
    const typingDelay = 100;
    let typingTimer: any;

    const getWorkers = async () => {
        try {
            const response = await getUsersFromGeofence(state.user?.token, geofence.id);
            setWorkers(response)
        } catch (error) {
            console.log(error)
        }
    }

    const handleAddRow = () => {
        const newRow = {
            id: workers.length + 1,
            name: ``,
            pts: ``,
            phoneNumber: ``,
        };
        setWorkers((prevRows: any) => [newRow, ...prevRows]);
    };

    const handleNameChange = async (id: any, newName: any) => {
        const updatedRows = workers.map((row: any) =>
        row.id === id
          ? {
              ...row,
              id: newName.userId,
              fullName: newName.fullName,
              pts: newName.pts,
              phoneNumber: newName.phoneNumber,
            }
          : row);
        setWorkers(updatedRows);
    };

    const handleCellEditCommit = async (params: any) => {
        const response = await addWorkerToGeofence(state.user?.token, geofence.id, params.row.id);
        if(response) {
            getWorkers();
            toast.success('Added worker successfully')
        }
    };

    const handleCellDelete = async () => {
        const response = await removeWorkerFromGeofence(state.user?.token, geofence.id, workerIdToDelete);
        if(response) {
            getWorkers();
            setDeleteModalIsOpen(false);
            toast.error('Worker deleted successfully')
        }
    };

    useEffect(() => {
        const fetchSuggestions = async () => {
            try {
                const response = await GetWorkersSuggestions(
                    state.user?.token,
                    inputValue
                );

                if (response) {
                   setSuggestions(response.data);
                } else {
                    console.error("Error fetching suggestions");
                }
            } catch (error) {
                console.error("Error:", error);
            }
        };

        if (inputValue) {
            typingTimer = setTimeout(fetchSuggestions, typingDelay);
        }
    
        return () => clearTimeout(typingTimer);
    }, [inputValue]);

    useEffect( () => {
        getWorkers();
    }, []);

    const columns: any = [
        {
            field: "name",
            headerName: "Full Name",
            flex: 1,
            renderCell: (params: any) => (
                <Autocomplete
                    size="small"
                    value={params.row}
                    options={suggestions || []}
                    disableClearable
                    style={{ margin: 5 }}
                    getOptionLabel={(option: any) => `${option?.fullName || ""}`}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    onChange={(event, newValue) => {
                        handleNameChange(params.id, newValue);
                    }}
                    renderOption={(props, option) => (
                        <ListItem {...props} key={option.fullName}>
                            {option.fullName} - {option.pts}
                        </ListItem>
                    )}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                onKeyDown: (event) => {
                                    if (event.key === ' ') {
                                        event.stopPropagation();
                                    }
                                },
                            }}
                        />
                    }
                />
            ),
        },
        {
            field: "pts",
            headerName: "PTS",
            flex: 0.4
        },
        {
            field: "phoneNumber",
            headerName: "Phone Number",
            flex: 0.4
        },
        {
            field: "SAVE",
            headerName: "Save",
            flex: 0.4,
            renderCell: (params: any) => (
                <Button variant="outlined" onClick={() => handleCellEditCommit(params)}>Save</Button>
            )
        },
        {
            field: "DELETE",
            headerName: "Delete",
            flex: 0.4,
            renderCell: (params: any) => (
                <Button
                    variant="contained"
                    style={{backgroundColor: 'red', boxShadow: ' none'}}
                    onClick={() => {
                        setWorkerIdToDelete(params.row.id);
                        setDeleteModalIsOpen(true);
                    }}
                >
                    Delete
                </Button>
            )
        },
    ];

    return (
        <div style={{marginTop: 30}}>
            <Box
                sx={{
                    width: '100%',
                    height: '450px'
                }}
            >
                <DataGrid
                    rows={workers}
                    columns={columns}
                    disableRowSelectionOnClick
                    sx={{"& .MuiDataGrid-columnHeaderTitle": {fontWeight: "bold"}}}
                />
            </Box>
            <Button
                variant="outlined"
                color="primary"
                onClick={handleAddRow}
                style={{margin: '2%'}}
            >
                Add New Worker
            </Button>
            <Modal
                title={`Are you sure you want to delete?`}
                centered
                open={deleteModalIsOpen}
                okButtonProps={{ danger: true }}
                cancelText={"NO"}
                okText={"YES"}
                onOk={handleCellDelete}
                onCancel={() => setDeleteModalIsOpen(false)}
            />             
        </div>
    );
};

export default Workers;