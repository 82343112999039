/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import { useSearchParams } from "react-router-dom";
import { DataGrid, GridToolbarFilterButton } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Checkbox,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  getPaymentMethod,
  getSignedInOptions,
  getWeeklyWorkersData,
  updateJobWorkerRows,
} from "../../../../api/apiCall";
import { useAuth } from "../../../../auth/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEnvelope, faX } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import { toast } from "react-toastify";

const DataTable = () => {
  const { state } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [date, setDate] = useState(null);
  const [week, setWeek] = useState<any>(searchParams.get("week") || 1);
  const [year, setYear] = useState<any>(searchParams.get("year") || 2024);
  const [workers, setWorkers] = useState<any>([]);
  const [signedInOptions, setSignedInOptions] = useState<any>([]);
  const [paymentMethods, setPaymentMethods] = useState<any>([]);

  const getBackgroundColor = (signedInValue: number) => {
    switch (signedInValue) {
      case 0:
        return "white";
      case 1:
        return "#ffc0cb";
      case 2:
        return "#90ee90";
      case 3:
        return "#FF0000";
    }
  };

  const handleChange = (id: any, field: any, newValue: any) => {
    const updatedRows = workers.map((row: any) =>
      row.id === id ? { ...row, [field]: newValue } : row
    );
    setWorkers(updatedRows);
  };

  const Toolbar = () => {
    return (
      <div>
        <GridToolbarFilterButton />
      </div>
    );
  };

  const getWorkers = async () => {
    const response = await getWeeklyWorkersData(state.user?.token, week, year);
    setWorkers(response);
  };

  const updateWorkers = async () => {
    try {
      const updatedRows = workers.map((worker: any) => ({
        ...worker,
        job: null,
      }));
      const response = await updateJobWorkerRows(state.user?.token, updatedRows);
      if (response?.data) {
        toast.success("Updated workers successfully");
      } else {
        toast.error("Something went wrong");
      }
      getWorkers();
    } catch (error) {
      console.log(error);
    }
  };

  const getSColor = (value: number) => {
    switch (value) {
      case 0:
        return "white";
      case 1:
        return "#90ee90";
      case 2:
        return "red";
      case 3:
        return "#f7de3a";
    }
  }

  const getTColor = (value: number) => {
    switch (value) {
      case 0:
        return "white";
      case 1:
        return "#90ee90";
      case 2:
        return "red";
    }
  }

  const columns: any = [
    {
      field: "whatsAppName",
      headerName: "What's App Name",
      width: 180,
      valueGetter: (params: any, row: any) => row.job?.whatsAppName,
      cellClassName: 'disable-columns',
    },
    {
      field: "project",
      headerName: "Project",
      width: 180,
      cellClassName: 'disable-columns',
      valueGetter: (params: any, row: any) => {
        const companyName = row.job?.project.company.companyName || '';
        const projectName = row.job?.project.projectName || '';
        return `${companyName} - ${projectName}`;
      },
    },
    {
      field: "shiftDate",
      headerName: "Shift Date",
      width: 110,
      cellClassName: 'disable-columns',
      valueGetter: (params: any, row: any) =>
        format(row.job?.jobDate, "dd/MM/yyyy"),
    },
    {
      field: "workRole",
      headerName: "Role",
      width: 160,
      cellClassName: 'disable-columns',
    },
    {
      field: "fullName",
      headerName: "Full Name",
      width: 150,
      cellClassName: 'disable-columns',
    },
    {
      field: "pts",
      headerName: "PTS",
      width: 100,
      cellClassName: 'disable-columns',
    },
    {
      field: "postCode",
      headerName: "Post Code",
      width: 100,
      cellClassName: 'disable-columns',
    },
    {
      field: "startTime",
      headerName: "Start Time",
      width: 110,
      cellClassName: 'disable-columns',
      headerAlign: "center",
      align: "center",
      valueGetter: (params: any, row: any) =>
        format(row.job?.startTime, "HH:mm"),
    },
    {
      field: "endTime",
      headerName: "End Time",
      width: 110,
      cellClassName: 'disable-columns',
      headerAlign: "center",
      align: "center",
      valueGetter: (params: any, row: any) =>
        format(row.job?.endTime, "HH:mm"),
    },
    {
      field: "t",
      headerName: "T",
      width: 80,
      headerAlign: "center",
      renderCell: (params: any) => (
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          style={{ height: "25px", width: "60px", backgroundColor: getTColor(params.row.t) }}
          value={params.row.t}
          onChange={(event, newValue: any) => {
            handleChange(params.id, "t", newValue?.props?.value);
          }}
        >
          <MenuItem value={0} style={{ height: 25 }}></MenuItem>
          <MenuItem
            value={1}
            style={{ backgroundColor: "#90ee90", height: 25 }}
          >
            <FontAwesomeIcon icon={faCheck} />
          </MenuItem>
          <MenuItem value={2} style={{ backgroundColor: "red", height: 25 }}>
            <FontAwesomeIcon icon={faX} />
          </MenuItem>
        </Select>
      ),
    },
    {
      field: "s",
      headerName: "S",
      width: 80,
      headerAlign: "center",
      renderCell: (params: any) => (
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          style={{ height: "25px", width: "60px", backgroundColor: getSColor(params.row.s) }}
          value={params.row.s}
          onChange={(event, newValue: any) => {
            handleChange(params.id, "s", newValue?.props?.value);
          }}
        >
          <MenuItem value={0} style={{ height: 25 }}></MenuItem>
          <MenuItem
            value={1}
            style={{ backgroundColor: "#90ee90", height: 25 }}
          >
            <FontAwesomeIcon icon={faCheck} />
          </MenuItem>
          <MenuItem value={2} style={{ backgroundColor: "red", height: 25 }}>
            <FontAwesomeIcon icon={faX} />
          </MenuItem>
          <MenuItem
            value={3}
            style={{ backgroundColor: "#f7de3a", height: 25 }}
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </MenuItem>
        </Select>
      ),
    },
    {
      field: "h_color",
      headerName: "H",
      width: 120,
      headerAlign: "center",
      renderCell: (params: any) => (
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          style={{ height: "25px", width: "110px", backgroundColor: getBackgroundColor(params.row.h_Color) }}
          value={params.row.h_Color}
          onChange={(event, newValue: any) => {
            handleChange(params.id, "h_Color", newValue?.props?.value);
          }}
        >
          {signedInOptions.map((options: any) => (
            <MenuItem
              value={options.value}
              style={{
                backgroundColor: getBackgroundColor(options.value),
                height: 25,
              }}
            >
              {options.name === "Neutral" ? "" : options.name}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      field: "paymentMethodId",
      headerName: "P",
      width: 140,
      headerAlign: "center",
      renderCell: (params: any) => (
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          style={{ height: "25px", width: "130px" }}
          value={params.row.paymentMethodId}
          onChange={(event, newValue: any) => {
            handleChange(params.id, "paymentMethodId", newValue?.props?.value);
          }}
        >
          {paymentMethods.map((options: any) => (
            <MenuItem
              value={options.id}
              style={{
                height: 40,
              }}
            >
              {options.paymentMethodName}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      field: "f",
      headerName: "F",
      width: 50,
      editable: false,
      headerAlign: "center",
      renderCell: (params: any) => (
        <Checkbox
          checked={params.row.f}
          onChange={(event, checked) => {
            handleChange(params.id, "f", checked);
          }}
        />
      ),
    },
    {
      field: "d",
      headerName: "D",
      width: 50,
      editable: false,
      headerAlign: "center",
      renderCell: (params: any) => (
        <Checkbox
          checked={params.row.d}
          onChange={(event, checked) => {
            handleChange(params.id, "d", checked);
          }}
        />
      ),
    },
    {
      field: "h",
      headerName: "Actual Hours",
      width: 130,
      headerAlign: "center",
      renderCell: (params: any) => (
        <TextField
          type="number"
          size="small"
          name="h"
          value={params.row.h}
          style={{ width: "100px", height: "5px", margin: 5 }}
          onChange={(event: any) => {
            handleChange(params.id, "h", event.target.value);
          }}
        />
      ),
    },
    {
      field: "hourlyRate",
      headerName: "Hourly Rate",
      width: 140,
      renderCell: (params: any) => (
        <>
          <>£</>
          <>
            <TextField
              type="number"
              size="small"
              name="hourlyRate"
              value={params.row.hourlyRate}
              style={{ width: "100px", height: "5px", margin: 5 }}
              onChange={(event: any) => {
                handleChange(params.id, "hourlyRate", event.target.value);
              }}
            />
          </>
        </>
      ),
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 130,
      editable: true,
    },
  ];

  useEffect(() => {
    setSearchParams({ week, year });
    getWorkers();
    getSignedInOptions(state.user?.token).then(setSignedInOptions);
    getPaymentMethod(state.user?.token).then(setPaymentMethods);
  }, [date]);

  return (
    <>
      <Header
        setDate={setDate}
        week={week}
        setWeek={setWeek}
        year={year}
        setYear={setYear}
      />
      <Box sx={{ height: "1000px", width: "94%" }} margin={1} mt={10}>
        <DataGrid
          rows={workers}
          columns={columns}
          slots={{ toolbar: Toolbar }}
          disableRowSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 50,
              },
            },
          }}
          pageSizeOptions={[50]}
          sx={{
            "@media (max-width: 700px)": {
              minWidth: "100%",
            },
            "& .MuiDataGrid-columnHeader": {
              color: "#333",
              fontWeight: "bold",
              fontSize: 15,
              backgroundColor: '#f0f0f0',
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "#f1f1f1",
            },
          }}
        />
      </Box>
      <div style={{ display: "flex", justifyContent: "left" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={updateWorkers}
          style={{
            margin: "10px",
            height: "40px",
            borderColor: "white",
            boxShadow: 'none',
          }}
        >
          Save Changes
        </Button>
      </div>
    </>
  );
};

export default DataTable;
