/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbarFilterButton } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { getStaffDetails } from "../../../api/apiCall";
import { useAuth } from "../../../auth/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";

const StaffDetails = () => {
  const { state } = useAuth();
  const [workers, setWorkers] = useState<any>([]);

  const Toolbar = () => {
    return (
      <div>
        <GridToolbarFilterButton />
      </div>
    );
  };

  const getWorkers = async () => {
    const response = await getStaffDetails(state.user?.token);
    console.log(response)
    setWorkers(response?.data);
  };

  const handleClick = (userId: any) => {
    window.open(`https://resourcing.pace-is.co.uk/PaceAdministrationTeam/Resourcing/WorkerDetails?workerId=${userId}`, '_blank')
  };

  const columns: any = [
    {
      field: "name",
      headerName: "Full Name",
      width: 220,
      renderCell: (params: any) => (
        <div
          onClick={() => handleClick(params.row.id)}
          style={{cursor: "pointer"}}
        >
          {params.row.name} {params.row.surname}
        </div>
      ),
    },
    {
      field: "suffix",
      headerName: "SUFFIX",
      width: 100,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: "pts",
      headerName: "PTS",
      width: 120,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 150,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
    },
    {
      field: "driver",
      headerName: "Driver",
      width: 90,
      headerAlign: "center",
      align: 'center',
      renderCell: (params: any) => (
        <div>
          {params.row.driver?
            (<FontAwesomeIcon icon={faCheck} size={'lg'} color={'green'} />)
            : (<FontAwesomeIcon icon={faX} size={'sm'}
             color={'red'} />)}
        </div>
      ),
    },
    {
      field: "postCode",
      headerName: "Post Code",
      width: 120,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: "location",
      headerName: "Location",
      width: 150,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 280,
      cellClassName: 'disable-columns',
    },
    {
      field: "dayRate",
      headerName: "Day Rate",
      width: 110,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'border-left',
      valueGetter: (params: any, row: any) => {
        const dayRate = row.userBasicRates?.dayRate || '0';
        return `£${dayRate}`;
      },
    },
    {
      field: "nightRate",
      headerName: "Night Rate",
      width: 110,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params: any, row: any) => {
        const nightRate = row.userBasicRates?.nightRate || '0';
        return `£${nightRate}`;
      },
    },
    {
      field: "weekendRate",
      headerName: "Weekend Rate",
      width: 150,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params: any, row: any) => {
        const weekendRate = row.userBasicRates?.weekendRate || '0';
        return `£${weekendRate}`;
      },
    },
  ];

  useEffect(() => {
    getWorkers();
  }, []);

  return (
    <>
      <Box sx={{ height: "1000px", width: "99%" }} margin={1} mt={6}>
        <DataGrid
          rows={workers}
          columns={columns}
          slots={{ toolbar: Toolbar }}
          disableRowSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
          pageSizeOptions={[100]}
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
              fontSize: 15
            },
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: '#f0f0f0',
              color: "#000",
              fontWeight: "bold",
            },
            '& .disable-columns': {
              borderRight: '1px solid #bfbfbf'
            },
            '& .border-left': {
              borderLeft: '1px solid #bfbfbf',
            },
          }}
        />
      </Box>
    </>
  );
};

export default StaffDetails;
